.MapControlPanel {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .map-controls {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0;

    .form-group {
      padding-right: 10px;
      padding-left: 10px;

      &:first-child {
        padding-left: 0;
      }

      &.last-item {
        padding-right: 0;
      }

      &.checkbox {
        margin-top: 24px;
      }

      label {
        font-family: 'Roboto' !important;
        font-weight: 700;
        font-size: 12px;
      }

      button {
        background-color: #403382;
        border: none;

        font-family: 'Futura PT', Roboto, 'Segoe UI' !important;
        font-weight: 700;
        font-size: 16px;
        color: #ffffff;
        text-transform: uppercase;

        width: 100%;
        height: 42px;

        padding: 0;
        margin: 18px 0 0;
      }

      @media (max-width: 991px) {
        padding: 0 0;
      }
    }
  }

  @media (min-width: 576px) {
    .map-controls {
      display: flex;
    }
  }

  @media (max-width: 991px) {
    margin-top: 28px;
  }

  .form-check label {
    font-weight: 700;
    font-size: 12px;
    color: #666666;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}
