.form-check-label {
  margin: 4px 0 0 6px !important;
}

.form-check {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ChargingMap {
       .map-disclaimer {
              margin:10px 0 0;
              color:#000000;

              a {
                     text-decoration: underline;
                     font-weight: bold;
                     color:#403382;
              }
       }
}