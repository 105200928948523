h1.title-page.pad-top {
       padding-top: 2rem;
}

h2.mb-bottom {
       margin-bottom: 50px;
}
.asked-questions {
       @media (max-width: 992px) {
                     margin: 2.5rem 2.5rem;
              }
}