.tableDetail {
  flex-direction: row;
  justify-content: space-between;
}

 @media (min-width: 576px) {
   .tableDetail {
     display: flex;
     height: 59px;
   }
 }

.tableColumn {
  width: 255px;

  :first-child {
    border-top: none;
  }
}

.tableCell {
  display: grid;
  grid-template-columns: 60% 40%;
  align-items: center;
  height: 40px;
  border-top: 1px solid #D9D9D6;

  .label {
    font-size: 14px;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: left;
  }

  .value {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
}
