.ChargingStationsList {
  margin-top: 2em;
  text-align: center;

  .show-full-list-container {
    button {
      border: none;
    }
  }
}
