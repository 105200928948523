@import 'src/client_customizations/scss/client_master.scss';

.homepage-jumbotron {
  padding-top: 62px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  row-gap: 91px;

  background-image: url('../../../client_customizations/assets/images/HomeBanner.png');

  .banner-subsection {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 17px;
    width: 100%;
    height: 134px;
    flex-shrink: 0;
    background-color: rgba(148, 140, 242, 0.5);
    padding: 47px;

    @media (max-width: 992px) {
      row-gap: 20px;
      flex-direction: column;
      padding: 30px;
      height: inherit;
    }

    .banner-subsection-text {
      color: #ffffff;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px; /* 128.571% */

      @media (max-width: 992px) {
        font-size: 18px;
        text-align: center;
      }
    }
  }

  .title-banner {
    padding: 0 199px;
    color: #fff;
    text-align: center;
    font-family: Futura PT;
    font-size: 130px;
    font-style: normal;
    font-weight: 700;
    line-height: 126px; /* 95% */
    text-transform: uppercase;

    @media (max-width: 992px) {
      font-size: 50px;
      line-height: inherit;
      padding: 40px;
    }
  }

  .title-banner-end {
    color: #948cf2;
  }
}

#why-choose-evs {
  padding: 0rem 1rem;
}
.big-promise-caption {
  top: 0;
  left: 0;
  position: absolute;
  padding: 1.5rem 1rem;
}
#HomepageVehiclesCarousel {
  background-color: #f3f3f3;
}

.homepage-titles-container {
  display: flex;
  row-gap: 17px;
  flex-direction: column;
  width: 100%;

  @media (max-width: 766px) {
    gap: inherit;
    flex-direction: column;
  }
    
}

.max-width {
  max-width: 877px;
}

.HomepageSubTitle {
  font-size: 20px;
  font-weight: 400;
  @media (max-width: 766px) {
    width: 95%;
    text-align: left;
  }
}

.primary {
  color: #2a2a2a;
  font-family: Futura PT;
  font-size: 47px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px; /* 119.149% */
  text-transform: uppercase;
}

.secondary {
  color: #403382;
  font-weight: 500 !important;
}

.HomepageFaq {
  padding: 75px 0;
  display: flex;
  background-color: #f8f8f8;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 5rem 17rem;
  max-width: 1100px;
  margin: 0 auto;

  @media (max-width: 992px) {
    margin: 2.5rem 2.5rem;
  }

  .HomepageFaqTitle {
    width: 65%;
    display: flex;
    gap: 10px;

    .questions {
      color: black;
      font-family: Futura PT;
    }

    .answered {
      font-family: Futura PT;
      font-weight: 600;
    }

    @media (max-width: 766px) {
      gap: inherit;
      flex-direction: column;
    }
  }

  .HomepageFaqSubTitle {
    width: 65%;
    font-size: 20px;
    font-weight: 400;
    font-family: Futura PT;

    @media (max-width: 766px) {
      width: 95%;
      text-align: center;
    }
  }

  .MuiAccordion-root {
    width: 70% !important;
    background-color: #f8f8f8;
    box-shadow: inherit;
    border-radius: inherit !important;
    border-top: 1px solid #576116;
  }
}

.container-home {
  padding: 5rem 17rem;

  @media (max-width: 992px) {
    padding: 2.5rem 2.5rem;
  }
}

.homepage-incentives {
  background: url('../../../client_customizations/assets/images/homepage_incentives_overlay.png');
  background-repeat: no-repeat;

  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  row-gap: 49px;
  padding: 5.3rem 0rem 3.75rem 0rem;
}

.homepage-incentives-title-container {
  display: flex;
  row-gap: 17px;
  max-width: 1100px;
  flex-direction: column;
  @media (max-width: 992px) {
      margin: 2.5rem 2.5rem;
    }
}

.homepage-incentives-title {
  color: #ffffff;
  font-family: Futura PT;
  font-size: 47px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 56px; /* 119.149% */
  text-transform: uppercase;

  .highline {
    font-weight: 500 !important;
  }
}

.homepage-incentives-title-sub {
  color: #ffffff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
}

.homepage-incentives-bar {
  background-color: rgba(221, 240, 252, 0.5);
  mix-blend-mode: screen;
  height: 112px;
  width: 100%;
}

.homepage-incentives-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;

  column-gap: 16px;
  row-gap: 16px;
  max-width: 1100px;
  margin:0 auto;
}

.homepage-home-charger-banner {
  background-color: #403382;
}

.homepage-home-charger-title-container {
  display: flex;
  flex-direction: column;
  row-gap: 17px;
  padding: 5rem 0rem 5rem 0rem;
  max-width: 1100px;
  margin:0 auto;

  @media (max-width: 992px) {
    padding: 2.5rem 2.5rem;
  }
}

.homepage-home-charger-title {
  color: #ffffff;
  font-family: Futura PT;
  font-size: 47px !important;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  max-width: 877px;

  .highline {
    font-family: Futura PT;
    font-size: 47px;
    font-weight: 500;
    line-height: 56px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.homepage-home-charger-title-sub {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
}

.homepage-home-charger-get {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 29px;
  background-color: rgba(221, 240, 252, 0.5);
}

.home-map-section-container {
       padding:0;

       .home-map-section {
              max-width:1100px;
              padding: 5.3rem 0 5rem;
              margin:0 auto;
       }
}
