.dicover-section {
  padding-top: 56px;
  padding: 30px;
  padding-bottom: 80px;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 80px;
}

.dicover-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 20px;
  max-width: 1100px;
}

.title-head {
  color: #000;
  font-family: Futura PT;
  font-size: 47px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 56px; /* 119.149% */
  text-transform: uppercase;
}

.title-head-discover {
  color: #403382;
  font-family: Futura PT;
  font-size: 47px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px;
  text-transform: uppercase;
}

.title-sub {
       font-family: "Roboto";
  color: #2a2a2a;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
}

.car-list {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  row-gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1100px;
}
