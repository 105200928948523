@import 'src/client_customizations/scss/client_master.scss';

.ComparedCarDetails {
  margin: 1.6rem 0;
  text-align: center;
  .btn {
    margin-top: 1.5rem;
  }

  .title {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
  }

  .value {
    color: #2a2a2a;
    text-align: center;
    /* H4 Sub Section Title */
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  p {
    margin-bottom: 0;
  }
  .afterIncentives {
    .DetailColumn {
      border-top: 1px solid #dbdbdb;
    }
  }
  .row {
    display: flex;
    justify-content: center;

    .DetailColumn {
      top: 0.4rem;
      padding: 0.5rem 0 1.12rem 0;
      border-bottom: 1px solid #dbdbdb;
      text-align: center;
    }
  }

  .h5 {
    font-size: 24px;
    font-weight: 600;
    color: $gray;
  }
}
