@import 'src/client_customizations/scss/client_master.scss';

.IncentiveCard {
  margin-right: 1%;
  margin-bottom: 1%;
  border-radius: $IncentiveCard-card-border-radius;
  border: none;
  border-top: 8px solid #948cf2;
  box-shadow: 0px 20px 41px rgba(0, 0, 0, 0.14);
  background-color: white;
  width: 255px;
  height: 369px;

  display: flex;
  flex-direction: column;

  &:hover {
    p {
      text-decoration-color: #948cf2 !important;
      text-decoration-line: underline;
    }
  }
}
.IncentiveCardBody {
  padding: 0rem 0.5rem 0.5rem 0.5rem;
  text-align: center;
  color: $IncentiveCard-card-body-color;
  border-radius: 0;

  .card-title {
    height: 55px;
    font-family: 'Futura PT' !important;
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 16px;
  }

  .light {
    font-family: 'Roboto, Open Sans', Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: center;
    max-width: 80%;
    margin: 24px auto 0;
  }

  img {
    margin-top: -12px;
    margin-bottom: -16px;
    width: 60px;
  }
}
.IncentiveCardTop {
  padding: 1rem;
  text-align: center;
  font-size: 14px;
  color: $IncentiveCard-card-body-color;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  border-radius: 0;
  font-weight: 400;
  .card-title {
    margin-top: 1rem;
    color: $IncentiveCard-card-title-color;
  }
  span {
    font-family: 'Roboto, Open Sans', Helvetica, sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color: #2a2a2a;
  }
}
.hrContainer {
  margin-top: auto;
}
hr {
  width: 100%;
  border-top: 2px solid #dadada;
}

.IncentiveCardBottom {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  min-height: 72px;
  padding: 1.5rem 1rem;
  @media (max-width: 992px) {
      margin: 2.5rem 2.5rem;
    }
}
.displayNone {
  display: none !important;
}

.showBtn {
  width: 100%;
  text-align: center;
  border: none;
  margin-top: 8px;
  background-color: transparent;

  .readFull {
    font-family: 'Futura PT' !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    color: #403382;
    text-transform: uppercase;
  }
}

.showAllCard {
  min-height: 369px !important;
  height: auto !important;
  max-height: 1000px !important;

  .description {
    min-height: 88px !important;
    height: auto !important;
    max-height: 1000px !important;
  }
}

@media (min-width: 768px) {
  .IncentiveCardBody {
    padding: 0rem 0.5rem 0.5rem 0.5rem;
  }

 
}
