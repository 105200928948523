@import 'src/client_customizations/scss/client_master.scss';
.Footer {
  background-color: $GenericFooter-background-color;
  padding: 60px;
  color: $GenericFooter-color;

  @media (max-width: 991px) {
    padding: 15px;
  }

  .disclaimer_container {
    font-family: Arial, Helvetica;
    display: flex;
    align-items: center;
    margin-left: '5px';
    font-size: 10px; /* Set the font size to 10px */
    max-width: 900px;

    div {
      background-color: transparent !important;

      img {
        max-width: 50%;
        margin: 0 0 15px;
      }

      p {
        color: white;
      }
    }
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;

  .subtext {
    font-family: Futura PT !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px; /* 171.429% */
    text-transform: uppercase;
  }

  .social-media-row {
    display: flex;
    flex-direction: row;
    column-gap: 24px;
  }

  .legal-info {
    text-align: center;
  }
}
