@import "src/client_customizations/scss/client_master.scss"; 

.inventory-card {
  padding-bottom: 16px;

  .row-item{
    border: 1px solid #DBDBDB;
    box-sizing: border-box;
  }

  .vehicle-img-wrapper{
    display: flex;
    align-items: center;
  }

  img {
    max-width:100%;
    max-height:100%;
  }
  .inventory-card-details {
    background-color: $Inventory-card-details-background;

    .detail {
      font-weight: bold;
    }
    .row {
      margin: 0;
    }
  }
  .contact-dealer, 
  .vehicle-details {
    text-align: center;
    padding: 10px 8px;
    font-size: 12px;
  }

  .vehicle-details {
    background-color: #0081C6;
    a {
      color: white;
    }
  }

  .contact-dealer {
    color: #0081C6;
    border: 1px solid #0081C6;
  }

  .model {
      font-weight: 700;
      font-size: 24px;
  }
  .make {
      font-size: 24px
  }

  .detail-title {
      text-transform: uppercase;
      font-size: 11px;
      padding-bottom: 8px;
  }

  .inventory-card-bottom {
      img {
          padding-right: 4px;
      }
      a {
          text-decoration: none;
      }
      .dealer-name {
        color: #0081C6;
        font-weight: bolder;
        font-size: 12px;
      }
  }
  .stock-image-disclaimer {
    p {
      font-size: .7rem;
    }
  }

} 

@media (min-width: 1200px) {
  .inventory-card {
    padding-bottom: 0px;
  }
}
