.evHead {
  font-family: Futura PT;
  font-size: 46px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
}

.lead {
  font-family: Roboto;
  font-size: 28px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;

  max-width: 931px;
  margin: 0 auto 50px;
}

.disclaimer {
  margin-top: 16px;

  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}