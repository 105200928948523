@import 'src/client_customizations/scss/client_master.scss';

.link-card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
  flex-wrap: wrap;
}
@media (max-width: 576px) {
  .link-card {
    width: 150px !important;
  }
}

.link-card {
  display: flex;
  text-align: center;
  cursor: pointer;
  height: 323px;
  width: 360px;
  justify-content: center;

  img {
       height:100px;
    margin: 30px 0px;
  }
  p {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
  &:hover {
    background-color: #403382;
    p {
      text-decoration: none;
    }
  }
  .evc-card {
    display: flex;
    align-items: center;
    border: none;
    flex-direction: column;
    justify-content: center;
    background-color: $LinkCard-evc-card-background-color;
    padding: 0.5rem;
    border-radius: 4px;
    margin-bottom: 20px;
    height: 100%;
    width: 362px;

    p {
      font-size: 0.7rem;
    }
    &:hover {
      background-color: $LinkCard-card-hover-body-color;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);

      .btn-homepage {
        color: #ffffff;
        text-align: center;
        font-family: Futura PT !important;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px; /* 110% */
        text-transform: uppercase;
      }
    }
    .btn-homepage {
      color: #2a2a2a;
      text-align: center;
      font-family: Futura PT !important;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 110% */
      text-transform: uppercase;
    }
  }
}
