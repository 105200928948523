@import "src/client_customizations/scss/client_master.scss";

.TabbedMaps {
  .container {
    @media (max-width: 450px) {
        padding: 0 !important;
      }
  }
  .tabbed-container {
    @media (max-width: 450px) {
      padding: 0;
    }
  }
  .tabbed-maps-panel {
    border-radius: 3px;
    border: 1px solid #dee2e6;
    box-shadow: 0 2px 5px 0px rgba(0,0,0, 0.15);
  }
  .tab-content {
    background-color: #ffffff;
    padding: 1rem 1.5rem;
    border-radius: 0 0 3px 3px;
    @media (max-width: 450px) {
      padding: 1rem;
    }
  }
  .nav-tabs {
    border-radius: 3px 3px 0 0;

    background-color: #ffffff;
    padding: 0 1.5rem;

    .nav-item {
      margin-bottom: 0;
    }

    .nav-link {
      padding: 1.5rem 0;
      margin: 0 1rem;
      
      cursor: pointer;
      border-radius: 0;
      border: none;

      color: #403382;
      font-family: 'Futura PT' !important;
      font-size: 20px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: uppercase;
    }

    .nav-item:first-child .nav-link {
      margin-left: 0;
    }

    .nav-link.active,
    .nav-item.show .nav-link,
    .nav-link:hover,
    .nav-link:focus {
      color: #403382;
      border-bottom: 6px solid #403382;
    }

  }

  .browse-inventory-button {
    background-color: #EE1C2E;
    color: #fff;
    padding: 0.75em 1.75em;
    font-size: 14px;
    border: none;
  }
}
