.title {
  font-size: 14px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: left;
}

.carDetailContainer {
  padding-top: 50px;
  padding-bottom: 30px;
  background: #FFFFFF;
}

.carDetailsTable {
  display: flex;
  flex-direction: column;
  row-gap: 30px;

    @media (min-width: 576px) {
      .carDetailsFooter {
        margin-top: 8rem;
  }
}
}
